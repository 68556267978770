import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from '../lib/redux/createStore';

const getWs = () => new WebSocket(import.meta.env.VITE_WS_URL);

export type ListenMessage = {
  type: 'MESSAGE';
  id: string;
  username: string;
  message: string;
};
type Message =
  | {
      type: 'PING' | 'PONG';
    }
  | {
      type: 'LISTEN';
      data: {
        channel: string;
      };
    }
  | ListenMessage;

type Timeout = ReturnType<typeof setTimeout>;

const config = {
  heartbeatInterval: 15000,
  pongTimeout: 10000,
  reconnectTimeout: 2000,
};

// https://developer.mozilla.org/fr/docs/Web/API/CloseEvent#status_codes
// allowed status codes for close event are 3000 and 4999 in our application
export const useWebsocket = (onMessage?: (msg: ListenMessage) => void) => {
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef<null | WebSocket>(null);
  const channel = useRef<{
    type: 'LISTEN';
    data: {
      channel: string;
    };
  } | null>(null);
  const heartBeat = useRef<null | Timeout>(null);
  const pongTimeout = useRef<null | Timeout>(null);
  const messageBuffer = useRef<Array<Message>>([]);
  const { isAuthenticated } = useTypedSelector((state) => state.auth);

  const flushMessageBuffer = () => {
    messageBuffer.current.forEach((message) => {
      wsRef.current?.send(JSON.stringify(message));
    });
    messageBuffer.current = [];
  };

  const sendMessage = (data: Message) => {
    if (data.type === 'LISTEN') {
      channel.current = data;
    }
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(data));
    } else {
      messageBuffer.current.push(data);
    }
  };

  const stopHeartbeat = () => {
    if (heartBeat.current) {
      clearInterval(heartBeat.current);
      heartBeat.current = null;
    }
    if (pongTimeout.current) {
      clearTimeout(pongTimeout.current);
      pongTimeout.current = null;
    }
  };

  const connect = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      return;
    }
    wsRef.current = getWs();
    wsRef.current.onopen = () => {
      setIsConnected(true);
      if (channel.current && wsRef.current?.readyState === WebSocket.OPEN) {
        wsRef.current?.send(JSON.stringify(channel.current));
      }
      flushMessageBuffer();
    };

    wsRef.current.onclose = (event) => {
      setIsConnected(false);
      if (event.code !== 1000) {
        setTimeout(() => {
          connect();
        }, config.reconnectTimeout);
      }
    };

    wsRef.current.onerror = () => {
      wsRef.current?.close();
    };

    wsRef.current.onmessage = async (event) => {
      const { data } = event;
      const message = JSON.parse(data.toString()) as Message;
      if (message.type === 'PING') {
        sendMessage({ type: 'PONG' });
      }
      if (message.type === 'PONG') {
        if (pongTimeout.current) {
          clearTimeout(pongTimeout.current);
          pongTimeout.current = null;
        }
      }
      if (message.type === 'MESSAGE') {
        onMessage?.(message);
      }
    };
  };

  const startHeartbeat = () => {
    heartBeat.current = setInterval(() => {
      if (wsRef.current?.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ type: 'PING' }));

        pongTimeout.current = setTimeout(() => {
          wsRef.current?.close();
          stopHeartbeat();
          wsRef.current = null;
          setTimeout(() => {
            connect();
          }, config.reconnectTimeout);
        }, config.pongTimeout);
      } else {
        if (heartBeat.current) {
          clearInterval(heartBeat.current);
        }
      }
    }, config.heartbeatInterval);
  };

  useEffect(() => {
    if (isAuthenticated) {
      connect();
    }

    if (wsRef.current?.readyState === WebSocket.OPEN && !isAuthenticated) {
      wsRef.current.close(1000);
    }

    return () => {
      if (wsRef.current) {
        if (wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.close(1000);
        }
        wsRef.current = null;
      }

      stopHeartbeat();
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (isConnected) {
      startHeartbeat();
    } else {
      stopHeartbeat();
    }
  }, [isConnected]);

  return {
    isConnected,
    sendMessage,
  };
};
