import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuthUserMutation } from '../../lib/redux/apiSlice';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

export const EmailStep: FC<{ flow: 'signup' | 'login' }> = ({ flow }) => {
  const [email, setEmail] = useState('');
  const [signup, { isLoading }] = useAuthUserMutation();
  const navigate = useNavigate();

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        try {
          await signup({ email, flow }).unwrap();
          navigate(`/${flow}/verify`);
        } catch (err) {
          console.log(err);
        }
      }}>
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        required
        placeholder="Email"
      />
      <div className="mt-2 text-xs">
        {flow === 'login' && (
          <span>
            Don't have an account?{' '}
            <Link
              className="font-semibold text-indigo-600 hover:text-indigo-500"
              to="/signup">
              Create an acount
            </Link>
          </span>
        )}
        {flow === 'signup' && (
          <span>
            Already have an account?{' '}
            <Link
              className="font-semibold text-indigo-600 hover:text-indigo-500"
              to="/login">
              Login
            </Link>
          </span>
        )}
      </div>
      <div className="mt-6">
        <Button className='w-full' disabled={isLoading} type="submit">
          {flow === 'signup' ? 'Sign up' : 'Login'}
        </Button>
      </div>
    </form>
  );
};
