import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { createRouter } from './lib/router/createRouter';
import { createStore } from './lib/redux/createStore';
import { ErrorScreen } from './pages/ErrorScreen.tsx';

const store = createStore();
const router = createRouter(store);

const App = () => (
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

const AppWithErrorBoundary = Sentry.withErrorBoundary(App, {
  fallback: ErrorScreen,
});

export default AppWithErrorBoundary;
