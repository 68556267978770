import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/broadcast`,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    authUser: builder.mutation<
      { id: string },
      { email: string; flow: 'signup' | 'login' }
    >({
      query: (body) => ({
        url: `/auth/${body.flow}`,
        method: 'POST',
        body: {
          email: body.email,
        },
      }),
    }),
    verifyCode: builder.mutation<{ id: string }, { code: string }>({
      query: (body) => ({ url: '/auth/otp', method: 'POST', body }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: '/auth/logout', method: 'POST' }),
    }),
    platformOAuth: builder.mutation<
      void,
      {
        code: string;
      }
    >({
      query: (body) => ({
        url: '/platforms/oauth/twitch',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAuthUserMutation,
  useVerifyCodeMutation,
  useLogoutMutation,
  usePlatformOAuthMutation,
} = apiSlice;
