import { createBrowserRouter } from 'react-router-dom';

import { SignUp } from '../../pages/Signup';
import { ErrorScreen } from '../../pages/ErrorScreen';
import { VerifyOtp } from '../../pages/Auth/VerifyOtp';
import { EmailStep } from '../../pages/Auth/EmailStep';
import { Dashboard } from '../../pages/Dashboard';
import { Login } from '../../pages/Login';
import { OAuth } from '../../pages/OAuth';
import { Platforms } from '../../pages/Dashboard/Platforms';
import { PrivateRoute } from '../../components/PrivateRoute';
import { AppStore } from '../redux/createStore';
import { getAuthThunk } from '../redux/authSlice';
import { AuthRoute } from '../../components/AuthRoute';

export const createRouter = (store: AppStore) => {
  const authLoader = () => {
    store.dispatch(getAuthThunk());
    return null;
  };

  return createBrowserRouter([
    {
      loader: authLoader,
      errorElement: <ErrorScreen />,
      path: '/',
      element: <Dashboard />,
      children: [
        {
          index: true,
          element: (
            <PrivateRoute redirectTo="/login">
              <Platforms />
            </PrivateRoute>
          ),
        },
        {
          path: 'redirect/:platform',
          element: <OAuth />,
        },
      ],
    },
    {
      path: '/signup',
      element: (
        <AuthRoute>
          <SignUp />
        </AuthRoute>
      ),
      loader: authLoader,
      children: [
        {
          index: true,
          element: <EmailStep flow="signup" />,
        },
        {
          path: 'verify',
          element: <VerifyOtp flow="signup" />,
        },
      ],
    },
    {
      path: '/login',
      element: (
        <AuthRoute>
          <Login />
        </AuthRoute>
      ),
      loader: authLoader,
      children: [
        {
          index: true,
          element: <EmailStep flow="login" />,
        },
        {
          path: 'verify',
          element: <VerifyOtp flow="login" />,
        },
      ],
    },
  ]);
};

export type AppRouter = ReturnType<typeof createRouter>;
