import './index.css';

import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './App.tsx';

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracePropagationTargets: ['back.youssefmeskini.me'],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
