import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';
import { apiSlice } from './apiSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export type Dependencies = Record<string, never>;

export const createStore = (dependencies?: Dependencies) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies,
        },
      }).concat(apiSlice.middleware),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
  });

  return store;
};

type AppStoreWithGetActions = ReturnType<typeof createStore>;
export type AppStore = Omit<AppStoreWithGetActions, 'getActions'>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, Dependencies, Action>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
