import { useMemo, useRef, useState } from 'react';
import { TwitchLogo } from '../../components/logos/TwitchLogo';
import { ListenMessage, useWebsocket } from '../../hooks/useWebsocket';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

const TWITCH_OAUTH_URL = 'https://id.twitch.tv';
const OAUTH_TWITCH_URL = `${TWITCH_OAUTH_URL}/oauth2/authorize?scope=chat:read+chat:edit&response_type=code&client_id=${
  import.meta.env.VITE_TWITCH_CLIENT_ID
}&redirect_uri=${import.meta.env.VITE_APP_URL}/redirect/twitch&force_verify=true`;

export const Platforms = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<ListenMessage[]>([]);
  const { sendMessage } = useWebsocket((msg) => {
    setMessages((prev) => [...prev, msg]);
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current.scrollHeight,
    });
  });
  const [channel, setChannel] = useState('');

  const lastHunderMessages = useMemo(() => {
    return messages.slice(-100);
  }, [messages]);

  return (
    <div className="mx-auto max-w-7xl p-2 sm:p-6 lg:p-8">
      <button
        className="w-full h-full"
        onClick={async () => {
          window.location.href = OAUTH_TWITCH_URL;
        }}>
        <TwitchLogo />
      </button>
      <Input value={channel} onChange={(e) => setChannel(e.target.value)} />
      <Button
        className="mt-2"
        onClick={() => {
          sendMessage({
            type: 'LISTEN',
            data: {
              channel,
            },
          });
        }}>
        Connect to twitch tchat
      </Button>
      <div ref={scrollContainerRef} className="overflow-y-auto max-h-96">
        {lastHunderMessages.map(({ id, message, username }) => (
          <div key={id}>
            {username}: {message}
          </div>
        ))}
      </div>
    </div>
  );
};
