import { FC, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@/components/ui/input-otp';

import { useVerifyCodeMutation } from '../../lib/redux/apiSlice';
import { Button } from '@/components/ui/button';

export const VerifyOtp: FC<{ flow: 'signup' | 'login' }> = ({ flow }) => {
  const [code, setCode] = useState('');
  const location = useLocation();
  const [verifyCode] = useVerifyCodeMutation();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <form
      ref={formRef}
      onSubmit={async (e) => {
        e.preventDefault();
        await verifyCode({ code }).unwrap();
        navigate(location?.state?.from || '/');
      }}
      className="space-y-10">
      <h1 className="text-md font-bold text-center">
        Please enter the code we sent you by email
      </h1>
      <InputOTP
        containerClassName="group flex items-center justify-center"
        onChange={setCode}
        maxLength={6}>
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
        </InputOTPGroup>
        <InputOTPSeparator />
        <InputOTPGroup>
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
      <Button className='w-full' type="submit">
        {flow === 'signup' ? 'Create your account' : 'Login'}{' '}
      </Button>
    </form>
  );
};
