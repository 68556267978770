import { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../lib/redux/createStore';

export const PrivateRoute: FC<PropsWithChildren<{ redirectTo: string }>> = ({
  children,
  redirectTo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useTypedSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate(redirectTo, { state: { from: location.pathname } });
    }
  }, [redirectTo, navigate, location, isAuthenticated, isLoading]);

  return children;
};
