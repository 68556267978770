import { Outlet } from 'react-router-dom';
import { NavBar } from '../../components/NavBar';

export const Dashboard = () => {
  return (
    <div className="flex flex-col h-full">
      <NavBar />
      <main className="flex-1">
        <Outlet />
      </main>
    </div>
  );
};
