import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';

const initialState = {
  isAuthenticated: false,
  isLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthThunk.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload;
        state.isLoading = false;
      })
      .addMatcher(apiSlice.endpoints.logout.matchFulfilled, (state) => {
        state.isAuthenticated = false;
        state.isLoading = false;
      })
      .addMatcher(apiSlice.endpoints.verifyCode.matchFulfilled, (state) => {
        state.isAuthenticated = true;
        state.isLoading = false;
      });
  },
});

export const getAuthThunk = createAsyncThunk(
  'auth/getAuth',
  async (_, { signal }) => {
    const response = await fetch(`${import.meta.env.VITE_API_URL}/broadcast/auth`, {
      credentials: 'include',
      signal,
      cache: 'no-store',
    });
    if (!response.ok) {
      return false;
    }
    const data = (await response.json()) as { isAuthenticated: boolean };
    return data.isAuthenticated;
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState() as { auth: typeof initialState };
      return auth.isLoading;
    },
  },
);

export default authSlice.reducer;
