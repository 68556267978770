import { useNavigate } from 'react-router-dom';

import { useLogoutMutation } from '../lib/redux/apiSlice';
import { Button } from './ui/button';

export const NavBar = () => {
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  return (
    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div className="relative flex h-16 items-center justify-between">
        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div className="flex flex-shrink-0 items-center">
            <img className="h-8 w-auto" src="/mark.svg" alt="Your Company" />
          </div>
          <Button
            onClick={async () => {
              await logout();
              navigate('/login');
            }}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};
