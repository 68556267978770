import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { usePlatformOAuthMutation } from '../../lib/redux/apiSlice';
import { Spinner } from '../../components/Spinner';

export const OAuth = () => {
  const { platform } = useParams<{ platform: 'twitch' }>();
  const navigate = useNavigate();
  const [searchParams, setURLParams] = useSearchParams();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const [platformOAuth] = usePlatformOAuthMutation();

  useEffect(() => {
    if (error) {
      setURLParams();
    }
    if (!code) return;
    const verifyPlatform = async () => {
      await platformOAuth({ code }).unwrap();
      navigate('/');
    };

    verifyPlatform();
  }, [code, platform, platformOAuth, navigate, error, setURLParams]);

  return (
    <div className="h-full flex items-center justify-center">
      <Spinner />
    </div>
  );
};
